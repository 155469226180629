<template>
  <div class="container completed">
    <Branding />
    <div v-if="completed">
      <h2>{{ contents.completed.title }}</h2>
      <p>{{ contents.completed.body }}</p>
      <div class="completed-content-info">
        <h4>Wait</h4>
        <p>
          <span>Whatsapp</span>
          <a href="https://wa.me/31642800166">+31 6 42800166</a>
        </p>
        <p>
          <span>Telefoon</span>
          <a href="tel:+0161453247">+31 161 453247</a>
        </p>
        <p>
          <span>Mail</span>
          <a href="mailto:binnendienst@wait.nu">binnendienst@wait.nu</a>
        </p>
      </div>
    </div>
    <div v-else>
      <p>Besteling niet afgerond</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { fetchStatus, isPaid } from '@/repository.js'

import contents from '../assets/contents.json'

import Branding from '@/components/Branding'

export default {
  name: 'Computed',
  components: {
    Branding,
  },
  setup: () => {
    const route = useRoute()

    const completed = computed(() => {
      if (!route.query.pid) return true
      return isPaid.value
    })

    if (route.query.pid) {
      fetchStatus(route.query.pid)
    }

    return { completed, contents }
  },
}
</script>
